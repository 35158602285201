<!-- 考试管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">考试列表</div>
      <div>
        <div>
          <el-button type="primary" style="height: 34px; line-height: 10px;margin-right:67px" class='cinput'
          >+添加考试</el-button
        >
        <el-radio-group v-model="radio" style="height: 34px" class='cinput'>
          <el-radio-button label="已发布"></el-radio-button>
          <el-radio-button label="未发布"></el-radio-button>
        </el-radio-group>
        </div>
        <div>
          <el-select v-model="value" placeholder="状态筛选" class='cinput ac' v-if='isshow2'>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="层级筛选" class='cinput ac' style="margin:0 23px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <div style="width:221px">
          <el-input v-model="input" placeholder="请输入搜索内容" class='cinput ac' @keyup.enter.native="search" ></el-input>
          <div style="display: flex;
    height: 34px;
    width:34px;
    align-items: center;
    background: #f7f7f7;">
            <img v-if='isshow' src="../../images/search2.png" alt="" @click="search" style="background:#F7F7F7"/>
          <img v-if='isshow==false' src="../../images/search1.png" alt="" style="background:#F7F7F7"/>
          </div>
        </div>
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%;min-height:600px"
      >
      <el-table-column
      type="index"
      width="50">
    </el-table-column>
      <el-table-column
        prop="date"
        label="层级"
        align='center'
        width="180">
      </el-table-column>
      <el-table-column
        prop="name"
        align='center'
        label="名称"
        >
      </el-table-column>
      <el-table-column
        prop="address"
        align='center'
        label="时间">
      </el-table-column>
      <el-table-column
        prop="address"
        align='center'
        width='180'
        label="状态">
        <template>
          <div class='red'>
            未开始
          </div>
        </template>
      </el-table-column>

        <el-table-column
          prop="address"
          label="操作"
          align="center"
          
        >
          <template>
            <div>
              <el-link type="primary" :underline="false">考试人员</el-link>
              <el-link type="primary" :underline="false" style="margin: 0 25px">查看详情</el-link>
              <el-link type="primary" :underline="false">取消发布</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
     <div style="display:flex;align-items:center;justify-content: center;">
        <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="100"
      >
      </el-pagination>
     </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
       options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }],
        value: '',
      isshow:false,
      isshow2:true,
      currentPage: 1,
      radio: "已发布",
      input: "",
     tableData: [{
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
          }, {
            date: '2016-05-04',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1517 弄'
          }, {
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }, {
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1516 弄'
          }]
 
    };
  },
  watch:{
    input(newdata,olddata){
      console.log(newdata)
      if(newdata){
        this.isshow=true
      }else{
        this.isshow=false
      }
    },
    radio(newdata,olddata){
      if(newdata=='已发布'){
        this.isshow2=true
      }else{
        this.isshow2=false
      }
    }
  },
  methods: {
    search(){
      console.log('我是搜索')
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner{
  border:none;
  background: #F7F7F7;
  // margin-right: 11px;
}
.red{
  color:#FF1B0B
}
</style>
